<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<template>
  <div class="media">
    <div class="mr-2" style="width: 3rem; max-height: 3rem; overflow-y: hidden">
      <img class="w-100" :src="stock.product.file_id | imageUrl" />
    </div>
    <div class="media-body">
      <div>{{ stock.product.name }}</div>
      <span
        >stock: <b>{{ stock.quantity }}</b></span
      >
    </div>
  </div>
</template>
<script>
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    stock: {
      default: () => ({})
    }
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style scoped></style>
