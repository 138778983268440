<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<template>
  <Multiselect
    :class="{ invalid: invalid }"
    v-model="internalValue"
    :options="options"
    placeholder="Selecciona Uno"
    selectLabel="Seleccionar"
    deselectLabel="Deseleccionar"
    selectedLabel="Seleccionado"
    :loading="loading"
    @search-change="find($event)"
    :internal-search="false"
    @open="openHandler"
    @select="selectHandler($event)"
    :disabled="disabled"
  >
    <template slot="singleLabel" slot-scope="props">
      <OptionLabel :stock="props.option"></OptionLabel>
    </template>
    <template slot="option" slot-scope="props">
      <OptionLabel :stock="props.option"></OptionLabel>
    </template>
    <span slot="noOptions">Sin resultados</span>
    <span slot="noResult">No hay resultados</span>
  </Multiselect>
</template>
<script>
import { debounce } from "lodash";
import OptionLabel from "./OptionLabel.vue";
import { LogisticService } from "../service.js";

export default {
  components: {
    OptionLabel
  },

  // directives
  // filters

  props: {
    disabled: {
      default: false
    },
    value: {
      default: undefined
    },
    invalid: {
      default: false
    },
    storeId: {
      //
    }
  },

  data: () => ({
    loading: false,
    internalValue: null,
    options: []
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    selectHandler(stock) {
      this.$emit("input", stock.id);
      this.$emit("select", stock);
    },
    reset() {
      this.internalValue = null;
      this.options = [];
    },
    async getOptions(search) {
      this.loading = true;
      let reponsePaginated = await LogisticService.getStocks({
        search,
        store_id: this.storeId
      });
      this.loading = false;
      this.options = reponsePaginated.data;
    },
    findDebounced: debounce(function(vm, search = "") {
      if (search.length > 0) vm.getOptions(search);
    }, 650),
    find(search) {
      this.findDebounced(this, search);
    },
    openHandler() {
      if (this.options.length == 0) {
        this.getOptions();
      }
    }
  }
};
</script>

<style scoped></style>
