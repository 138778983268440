<template>
  <select class="custom-select" v-model="valueChild">
    <option v-for="(l, index) in list" :key="index" :value="index"
      >{{ l }}
    </option>
  </select>
</template>

<script>
export default {
  computed: {
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    list() {
      return this.$store.state.config.logistic
        ? this.$store.state.config.logistic.outputs.types
        : [];
    }
  },
  props: {
    value: {}
  }
};
</script>

<style></style>
