<template>
  <div>
    <div class="row">
      <div class="form-group col-lg-8">
        <label for>Tipo de Salida</label>
        <SelectType :disabled="disabled" v-model="type_id"></SelectType>
        <app-invalid-feedback :errors="errors.type_id"></app-invalid-feedback>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-4">
        <label for>Almacen Origen</label>
        <SelectStore
          :disabled="disabled"
          ref="selSto1"
          v-model="store_id"
        ></SelectStore>
        <app-invalid-feedback :errors="errors.store_id"></app-invalid-feedback>
      </div>
      <div class="form-group col-lg-4" v-show="type_id == 2">
        <label for>Almacen destino</label>
        <SelectStore
          :disabled="disabled"
          ref="selSto2"
          v-model="target_store_id"
        ></SelectStore>
        <app-invalid-feedback
          :errors="errors.target_store_id"
        ></app-invalid-feedback>
      </div>
      <!-- <div class="form-group">
      <label for>Tipo de moneda</label>
      <app-select-currency :verbose="true" v-model="reg.money_code" :disabled="disabled"></app-select-currency>
      <app-invalid-feedback :errors="errors.money_code"></app-invalid-feedback>
    </div> -->
    </div>
    <app-button-loading
      :loading="sending"
      v-show="!disabled"
      @click="save()"
      :disabled="errors"
    >
      Continuar
    </app-button-loading>
  </div>
</template>

<script>
import SelectType from "./SelectType";
import SelectStore from "../stores/Select";
//import validate from "validate.js";
import { LogisticService } from "../service";
import validator from "src/lt/utils/validator.js";

const formRules = function(reg) {
  var targetRules = {};
  if (reg.type_id == 2) {
    targetRules = {
      presence: true,
      exclusion: {
        within: [reg.store_id],
        message: "no puedes usar ese destino"
      }
    };
  }
  return {
    type_id: { presence: { message: "Requerido" } },
    store_id: { presence: { message: "Requerido" } },
    target_store_id: targetRules
    // money_code: { presence: { message: "Requerido" } }
  };
};

export default {
  components: {
    SelectType,
    SelectStore
  },

  // directives
  // filters

  props: {
    disabled: {
      default: false
    }
  },

  data: () => ({
    store_id: null,
    target_store_id: null,
    type_id: null,
    sending: false
  }),

  computed: {
    errors() {
      return validator(this.$data, formRules(this.$data));
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    setValue(reg) {
      this.type_id = reg.type_id;
      this.$refs.selSto1.setValueFromId(reg.store_id);
      this.$refs.selSto2.setValueFromId(reg.target_store_id);
    },
    reset() {
      this.reg = {};
    },
    async save() {
      try {
        this.sending = true;
        let res = await LogisticService.saveOutput(this.$data);
        this.$emit("submitted", res.id);
      } catch (e) {
        //
      }
      this.sending = false;
    }
  }
};
</script>

<style scoped></style>
