<template>
  <div>
    <div class="form-group">
      <label for>Producto</label>
      <!-- <SelectProduct ref="selPro" :store_id="store_id" v-model="reg.product_id"></SelectProduct> -->
      <SelectStock
        ref="elSelectStock"
        :storeId="store_id"
        @select="
          product_id = $event.product_id;
          max_quantity = $event.quantity;
        "
      ></SelectStock>
      <app-small-form-errors
        :errors="errors.product_id"
      ></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for>Cantidad</label>
      <input
        type="number"
        v-model.number="quantity"
        class="form-control"
        step="1"
      />
      <app-small-form-errors :errors="errors.quantity"></app-small-form-errors>
    </div>
    <app-button-loading
      @click="save()"
      :loading="loading"
      :disabled="errors"
    ></app-button-loading>
    <div class="alert alert-danger mt-3 mb-0" v-show="errorMessage.length > 0">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
//import SelectProduct from "../../store-module/products/Select";
//import validate from "validate.js";
import { LogisticService } from "../service";
import SelectStock from "../stock/Select.vue";
import validator from "src/lt/utils/validator.js";

const formRules = function(context) {
  return {
    product_id: { presence: true },
    quantity: {
      presence: { allowEmpty: false },
      numericality: {
        greaterThan: 0,
        lessThanOrEqualTo: context.quantity
      }
    }
  };
};

export default {
  components: {
    SelectStock
  },

  // directives
  // filters

  props: {
    output_id: {
      required: true
    },
    store_id: {
      required: true
    }
  },

  data: () => ({
    max_quantity: null,
    product_id: null,
    quantity: null,
    loading: false,
    errorMessage: ""
  }),

  computed: {
    errors() {
      return validator(
        this.$data,
        formRules({
          quantity: this.max_quantity
        })
      );
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    reset() {
      this.max_quantity = null;
      this.product_id = null;
      this.quantity = null;
      this.loading = false;
      this.errorMessage = "";
      this.$refs.elSelectStock.reset();
    },
    async save() {
      this.loading = true;
      try {
        let res = await LogisticService.saveOutputDetail({
          product_id: this.product_id,
          quantity: this.quantity,
          output_id: this.output_id
        });
        this.$emit("submitted", res);
      } catch (e) {
        this.errorMessage = e.response.data.message;
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
